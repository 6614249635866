<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-search-box @enter="getList">
          <template slot="search">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <!-- 사용여부 -->
              <c-select
                :comboItems="useFlagItems"
                type="search"
                itemText="codeName"
                itemValue="code"
                name="useFlag"
                label="LBLUSEFLAG"
                v-model="searchParam.useFlag"
              ></c-select>
            </div>
          </template>
        </c-search-box>
        <c-table
          ref="table"
          title="지도 목록"
          tableId="riskHazrd01"
          :columns="grid.columns"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          :changeData="grid.changeData"
          :editable="editable"
          @linkClick="linkClick"
          @getTableHeight="(height) => { this.imgHeight = height }"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="add" />
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='src'">
              <q-btn 
                round unelevated 
                size="6px"
                color="amber" 
                icon="search"
                @click="imageLoad(props.row, props.pageIndex)" />
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <c-card title="지도 미리보기" class="cardClassDetailForm" :noMarginPadding="true" :height="setImgHeight"> 
          <template slot="card-button">
            <q-btn-group outline >
              <q-btn
                v-if="haveMap"
                class="custom-btn"
                size="md"
                text-color="white"
                color="teal-6"
                @click="toggle"
                :icon="$q.fullscreen.isActive ? 'fullscreen_exit' : 'fullscreen'"
                label="지도 크게 보기"
              ></q-btn>
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-12">
              <q-img :src="mapSrc" fit="scale-down" ref="image"
                :contain="true"
                :ratio="1">
                <template v-slot:loading>
                  <q-spinner-gears color="white" />
                </template>
              </q-img>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'work-map',
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      file: null,
      imgHeight: '',
      mapSrc: require('@/assets/images/no-image.png'),
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width:85px',
          },
          {
            name: 'mapName',
            field: 'mapName',
            label: '지도명',
            align: 'left',
            sortable: true,
            style: 'width:200px',
            type: 'link',
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: 'LBLSORTORDER',
            align: 'center',
            sortable: true,
            style: 'width:40px',
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            sortable: true,
            style: 'width:40px',
          },
          {
            name: 'src',
            field: 'src',
            label: '미리보기',
            align: 'center',
            style: 'width:40px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
        changeData: false,
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setImgHeight() {
      let returnText = '';
      returnText = this.imgHeight ? (Number(this.imgHeight.replace('px', '')) + 80) + 'px' : '500px'
      return returnText;
    },
    haveMap() {
      return this.mapSrc !== require('@/assets/images/no-image.png')
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.swp.map.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        this.grid.changeData = !this.grid.changeData
      },);
    },
    /* eslint-disable no-unused-vars */
    imageLoad(row) {
      if (row) {
        this.$comm.previewImage({
          sysAttachFileId: row.sysAttachFileId,
          contentType: row.contentType,
        }).then(_result => {
          this.mapSrc = _result;
        });
      } else {
        this.mapSrc = require('@/assets/images/no-image.png')
      }
    },
    toggle(e) {
      const target = this.$refs['image'].$el; // e.target.parentNode.parentNode.parentNode
      this.$q.fullscreen.toggle(target)
        .then(() => {
          // success!
        })
        .catch(() => {
          // error!
        })
    },
    add() {
      this.linkClick();
    },
    linkClick(row, col, index) {
      this.popupOptions.title = '지도 상세';
      this.popupOptions.param = {
        sopMapId: row ? row.sopMapId : '',
      };
      this.popupOptions.target = () => import(`${'./workMapDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
<style lang="sass">
.work-map-chip
  .q-chip__content
    display: none

</style>